import React, { PureComponent } from 'react';
import './TransitionTestPage.css'

export default class TestPage extends PureComponent {
	render() {
		return(
			<div className="TestPage">
			</div>

			)
	}
}