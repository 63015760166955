import React, { PureComponent } from 'react';
import WereTheyCancelled from './weretheycancelled.js'

export default class Password extends React.Component {
	constructor(props) {
        super(props)
        this.state = {
            next: false,
        }

        this.handleInput = this.handleInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleInput(e) {
        this.setState({password: e.target.value})
    }

    handleSubmit(e) {
        fetch('https://cshanehsaz.com/wtcdata/checkpw?pw='+ String(this.state.password))
        .then(res => res.json())
        .then(res =>
            this.setState({next: res.correct}))
    }

	render() {
        if(this.state.next) {
            return(
                <WereTheyCancelled />
            )
        } else {
            return(
                <div>
                    <p> Enter password to continue. </p>
                    <label> Password:
                        <input type="password" onChange={this.handleInput}></input>
                    </label>
                    <button onClick={this.handleSubmit}>Submit</button>
                </div>)
        }
	}
}