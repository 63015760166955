import React, { Component } from 'react'
import {Container, Row, Col, Button, Image} from 'react-bootstrap'
import './Searchbar.css'
import Fetcher from '../Fetcher/Fetcher.js'
import dddrest from '../Web Scraper/dddrest.json'
import Displayddd from '../Displayddd/Displayddd.js'

import guyfieri from '../Assets/guyfieri.gif'


export default class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: 'NYC',
      category: this.props.category,
      clicked: 0,
      ddd: dddrest,
    }

    this.handleInput = this.handleInput.bind(this);
    this.buttonClickSet0 = this.buttonClickSet0.bind(this);
    this.buttonClickSet1 = this.buttonClickSet1.bind(this);
    this.buttonClickSet2 = this.buttonClickSet2.bind(this);
  }

  handleInput(event) {
    this.setState({
      search: event.target.value,
    });
  }

  //0 is default
  //1 is yelp
  //2 is triple d

  buttonClickSet0() {
    this.setState(state => ({clicked: 0}))
  }

  buttonClickSet1() {
    this.setState(state => ({clicked: 1}))
  }

  buttonClickSet2() {
    this.setState(state => ({clicked: 2}))
  }

  render() {
      if (this.state.clicked === 0) {
        
        return(
          <div className="text">
            <input
              className="Searchbar"
              onInput={this.handleInput}
              placeholder={this.state.category}
            />

            <br />
            
            <Button onClick={this.buttonClickSet1}>Find some good food near me<br />
                (according to boring old Yelp)
            </Button>
            
            <p>or...</p>

            <Button onClick={this.buttonClickSet2}> 
              Go to Flavortown! &nbsp;
              <Image rounded className="Guyfieri" src={guyfieri} />
            </Button>

            <div>
              <br />
              <br />
             
            </div>
          </div>
        )
      }


      if (this.state.clicked === 1) {
        return(
          <div>
            <Container>
              <Row><Col>
                <Button onClick={this.buttonClickSet0}>Search Again</Button>
              </Col></Row>
            </Container>
            <br />
              <Fetcher search={this.state.search} />
          </div>
          )
      }


      if (this.state.clicked === 2) {    

        return(
          <div>
            <Container>
              <Row><Col>
                <Button onClick={this.buttonClickSet0}>Search Again</Button>
              </Col></Row>
            </Container>
            <br />
            <Displayddd />
          </div>
          )
      }



  }
}