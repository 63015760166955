import React, { Component } from 'react';
import Table from '../Table/table.js'
import {Container, Row, Col} from 'react-bootstrap'
import { PropagateLoader } from 'react-spinners'
import { css } from '@emotion/core';

import './Fetcher.css'

export default class Fetcher extends Component {
	constructor(props) {
		super(props);

		this.state = {
			search: this.props.search,
			loading: true,
			results: [],
		}
		//this.fill.bind here whatever functions we make
	}

	componentDidMount() {
		const endpoint = "https://cors-anywhere.herokuapp.com/https://api.yelp.com/v3/businesses/search"
		const params = `location=${this.state.search}`

		fetch(`${endpoint}?${params}` ,
		{method: "GET",
		headers: {
			Authorization: 'Bearer VN9LXXNigdeQodrKry7A7b8knQLwqR95V2dD2ne7bmlED1tbp9igIdeFpQfIYcVs6ojM9UNdpWtDdLN1bJ7L_jZVnnbbndVpaH-b1SIAm8l1x3WZoBjWONvr0oKZXHYx'
		}
		})
		.then(res => res.json())
		.then( (result) => {
			this.setState({
				loading: false,
				results: result
			})
		})
	}



	render() {

		if ( this.state.loading === true) {

			const override = css`
	        position: relative;
	        left: 15%;
	        `

			return(
				<div className="Fetcher_loader">
					<Container>
			              <Row> 
				              <Col></Col> 
				              <Col>
				                    <PropagateLoader  css={override} color='#007bff'/>
				              </Col>
				              <Col></Col>
			              </Row>
			         </Container>
				</div>
			)
		}


		if ( this.state.loading === false) {
			if (this.state.results.error !== undefined){
				return(
					<p> 
						Location not recognized. Please try again with a different location.
					</p>
					)
			}


			return(

				<div className="Grid_test">
				{this.state.results.businesses.map(function(place) {
					return (
						<div key={place.name} className="Grid_item">
							<Table
								
								key={place.id}
								name={place.name}
								phone={place.phone}
								address={place.location.display_address}
								rating={place.rating}
								image_url={place.image_url}
							/>
							<br />
						</div>
							)
						}
					)
				}
				</div>
			)
		}
	}

}