import React, { PureComponent } from 'react';
import './MySite.css';

import {
  BrowserRouter,
  Route,
  Link,
} from 'react-router-dom'

import Contact from './components/Contact.js'
import MyNavbar from './components/Navbar.js'
import Virusimr from './corona/Virusimr.js'

class MySite extends PureComponent {
  render() {
    if(window.location.pathname === '/coronavirus') {
			return(
				<Virusimr/>
			)
		}
    return (
      <div className="App">
        <MyNavbar />
        
      {/*Navbar currently is in charge of rendering everything between here*/}

        <Contact />
      </div>
    );
  }
}

export default MySite;
