import React, { PureComponent } from 'react';
import './Blog.css'
import Post1 from '../Assets/BlogPosts/Post1.js'
import Post2 from '../Assets/BlogPosts/Post2.md'
import ReactMarkdown from 'react-markdown'
const fs = require('fs')
		  
export default class Blog extends PureComponent {
	constructor(props) {
		super(props)
		this.state = { terms: null }
	}

	componentWillMount() {
		const fs = require('fs')
		let postFolder = '../Assets/BlogPosts'
		fs.readdir(postFolder, (err, files) => {
			files.forEach(file => {
				console.log(file)
			})
		})

		fetch(Post2).then((response) => response.text()).then((text) => {
		this.setState({ terms: text })
		})
	}

	render() {
		return (
			<div className="Blog">
                <Post1 />
				<ReactMarkdown source={this.state.terms}/>
			</div>

			)

	}
}