import React, { Component } from 'react';
import { Card } from 'react-bootstrap'
import './table.css'

export default class Table extends Component {
	constructor(props) {
		super(props)

		this.state = {
			businesses: this.props.businesses
		}
	}

	render() {
		return(
			<div className="Card_text">
				<Card className="Card" style={{ width: '18rem' }}>
					<Card.Img className="Card_image" variant="top" src={this.props.image_url} />
					<Card.Body>
						<Card.Title>{this.props.name}</Card.Title>
					    <Card.Text>
					    	{this.props.address.map((part) => 
								part.concat(' '))
							} <br />
							Phone: {this.props.phone} <br />
							Rating:{this.props.rating}
					    </Card.Text>
					</Card.Body>
				</Card>
			</div>
			)
	}
}