import React, { Component } from 'react';
import { Card } from 'react-bootstrap'
import './Tableddd.css'

export default class Tableddd extends Component {
	constructor(props) {
		super(props)

		this.state = {
			name: this.props.name,
			add: this.props.add
		}
	}

	render() {
		return(
			<div className="Table">
				<h6>{this.state.name}</h6>
				<h6>{this.state.add}</h6>
			</div>
			)
	}
}