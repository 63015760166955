import React, { Component, PureComponent } from 'react';
import {
  BrowserRouter,
  Route,
  Link,
} from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Image from 'react-bootstrap/Image'

import Contact from './Contact.js'
import Home from './Home.js'
import './About1.css'

import rockclimbing from '../Assets/rockclimbing.svg'
import drumming from '../Assets/drumming.png'
import badum from '../Assets/badum.gif'
import doodle from '../Assets/doodle.png'

//This is the transitions version of the normal about page
//TODO: Make it detech the width of the window and load text in based on relative rather than absolute position

export default class About1 extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	part2loaded: false,
	    	part3loaded: false
	    }

		this.handleScroll = this.handleScroll.bind(this);
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  	}
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
		window.addEventListener('resize', this.updateWindowDimensions)
		this.handleScroll()
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
		window.removeEventListener('resize', this.updateWindowDimensions)
	}

	updateWindowDimensions = () => {
	}

	handleScroll = () => {
		console.log(window.scrollY+document.documentElement.clientHeight, window.document.body.offsetHeight, 
			(window.scrollY+document.documentElement.clientHeight)/window.document.body.offsetHeight)

		let scrollPosition = (window.scrollY+document.documentElement.clientHeight)/window.document.body.offsetHeight
		if(scrollPosition < .6 ) {
			if(this.state.part2loaded === true) { return }
			this.setState(state => ({part2loaded: false}))
			this.setState(state => ({part3loaded: false}))
		}
		else if(scrollPosition < .8) {
			if(this.state.part3loaded === true) { return }
			this.setState(state => ({part2loaded: true}))
			this.setState(state => ({part3loaded: false}))
		}
		else {
			this.setState(state => ({part2loaded: true}))
			this.setState(state => ({part3loaded: true}))
		}

	}

	//Render Function
	render() {
		if(this.state.part2loaded === false && this.state.part3loaded === false) {
			return(
				<div className="About" onScroll={this.handleScroll}>
				<div className="About_photos">
					<img className="About_rockclimbing" src={rockclimbing} />
					<img className="About_drumming" src={drumming} />
				</div>
				<div className="About_title">
						<h1 className="About_title2">
							Some Things About Me 
						</h1>
						<div className="Text_part1_post">
							<p> 
								Hi, my name is Cyrus Shanehsaz. I was born in the great
								unknown state of Delaware and grew up on a small farm just 
								outside of the capital. We weren't farmers, but my dad was
								a <br/><br/>
								<h4>PHARMacist.</h4>
								<br/>
							</p>
							<img className="Badum" src={badum} />
							<br/><br/><br/>
						</div>
						<div className="Text_part2_pre">
							<p>
								I spent my childhood climbing trees,
								playing in a "band" with my two best friends, 
								and playing way too many video games.
								<br/><br/>
								Throughout middle and high school I developed passions
								for lots of things, but found myself pursuing technology
								and politics most heavily. I split my time between 
								fiddling with Arduino's, reading the news, schoolwork, and a few
								other hobbies.
								<br/><br/>
							</p>
						</div>
						<div className="Text_part3_pre">
							<p>
								After a lot of work I made it to the University of Pennsylvania
								where I now study business and statistics in the Wharton School.
								However, I found myself missing science, math and technology. I've started to
								take some computer science courses and learn a little web development
								on the side (and built this website) to catch up.
								<br/><br/>
								Nowadays I spend my time rock climbing, drumming, and
								learning all I can while I have the freedom to do so.
								<br/><br/>
								Feel free to check out my apps and resume above if
								you're looking for some of my work. If you have any
								questions, suggestions, or comments don't hesitate 
								to contact me! You can reach me at my info below. 
								<br/><br/>
								Thanks for reading!

							</p>
						</div>
				</div>
				</div>


			)
		} else if(this.state.part2loaded === true && this.state.part3loaded === false){
			return(
				<div className="About" onScroll={this.handleScroll}>
				<div className="About_photos">
					<img className="About_rockclimbing" src={rockclimbing} />
					<img className="About_drumming" src={drumming} />
				</div>
				<div className="About_title">
						<h1 className="About_title2">
							Some Things About Me 
						</h1>

						<div className="Text_part1_post">
							<p> 
								Hi, my name is Cyrus Shanehsaz. I was born in the great
								unknown state of Delaware and grew up on a small farm just 
								outside of the capital. We weren't farmers, but my dad was
								a <br/><br/>
								<h4>PHARMacist.</h4>
								<br/>
							</p>
							<img className="Badum" src={badum} />
							<br/><br/><br/>
						</div>
						<div className="Text_part2_post">
							<p>
								I spent my childhood climbing trees,
								playing in a "band" with my two best friends, 
								and playing way too many video games.
								<br/><br/>
								Throughout middle and high school I developed passions
								for lots of things, but found myself pursuing technology
								and politics most heavily. I split my time between 
								fiddling with Arduino's, reading the news, schoolwork, and a few
								other hobbies.
								<br/><br/>
							</p>
						</div>
						<div className="Text_part3_pre">
							<p>
								After a lot of work I made it to the University of Pennsylvania
								where I now study business and statistics in the Wharton School.
								However, I found myself missing science, math and technology. I've started to
								take some computer science courses and learn a little web development
								on the side (and built this website) to catch up.
								<br/><br/>
								Nowadays I spend my time rock climbing, drumming, and
								learning all I can while I have the freedom to do so.
								<br/><br/>
								Feel free to check out my apps and resume above if
								you're looking for some of my work. If you have any
								questions, suggestions, or comments don't hesitate 
								to contact me! You can reach me at my info below. 
								<br/><br/>
								Thanks for reading!

							</p>
						</div>
				</div>
				</div>
			)
		} else {
			return(
				<div className="About" onScroll={this.handleScroll}>
				<div className="About_photos">
					<img className="About_rockclimbing" src={rockclimbing} />
					<img className="About_drumming" src={drumming} />
				</div>
				<div className="About_title">
						<h1 className="About_title2">
							Some Things About Me 
						</h1>

						<div className="Text_part1_post">
							<p> 
								Hi, my name is Cyrus Shanehsaz. I was born in the great
								unknown state of Delaware and grew up on a small farm just 
								outside of the capital. We weren't farmers, but my dad was
								a <br/><br/>
								<h4>PHARMacist.</h4>
								<br/>
							</p>
							<img className="Badum" src={badum} />
							<br/><br/><br/>
						</div>
						<div className="Text_part2_post">
							<p>
								I spent my childhood climbing trees,
								playing in a "band" with my two best friends, 
								and playing way too many video games.
								<br/><br/>
								Throughout middle and high school I developed passions
								for lots of things, but found myself pursuing technology
								and politics most heavily. I split my time between 
								fiddling with Arduino's, reading the news, schoolwork, and a few
								other hobbies.
								<br/><br/>
							</p>
						</div>
						<div className="Text_part3_post">
							<p>
								After a lot of work I made it to the University of Pennsylvania
								where I now study business and statistics in the Wharton School.
								However, I found myself missing science, math and technology. I've started to
								take some computer science courses and learn a little web development
								on the side (and built this website) to catch up.
								<br/><br/>
								Nowadays I spend my time rock climbing, drumming, and
								learning all I can while I have the freedom to do so.
								<br/><br/>
								Feel free to check out my apps and resume above if
								you're looking for some of my work. If you have any
								questions, suggestions, or comments don't hesitate 
								to contact me! You can reach me at my info below. 
								<br/><br/>
								Thanks for reading!

							</p>
						</div>
				</div>
				</div>
			)
		}
	}
}