import React, { PureComponent } from 'react';
import {
  BrowserRouter,
  Route,
  Link,
} from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Image from 'react-bootstrap/Image'

import Contact from './Contact.js'
import Home from './Home.js'
import './About.css'

import rockclimbing from '../Assets/rockclimbing.svg'
import drumming from '../Assets/drumming.png'
import badum from '../Assets/badum.gif'

export default class About extends PureComponent {
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
		console.log(window.scrollY)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}

	render() {
		return(
			<div className="About">
			<div className="About_photos">
				<img className="About_rockclimbing" src={rockclimbing} />
				<img className="About_drumming" src={drumming} />
			</div>
			<div className="About_title">
					<h1 className="About_title2">
						Some Things About Me 
					</h1>
					<p> 
						Hi, my name is Cyrus Shanehsaz. I was born in the great
						unknown state of Delaware and grew up on a small farm just 
						outside of the capital. We weren't farmers, but my dad was
						a <br/><br/>
						<h4>PHARMacist.</h4>
						<br/>
					</p>
					<img className="Badum" src={badum} />
					<br/><br/><br/>
					<p>
						I spent my childhood climbing trees,
						playing in a "band" with my two best friends, 
						and playing way too many video games.
						<br/><br/>
						Throughout middle and high school I developed passions
						for lots of things, but found myself pursuing technology
						and politics most heavily. I split my time between 
						fiddling with Arduino's, reading the news, schoolwork, and a few
						other hobbies.
						<br/><br/>
						After a lot of work I made it to the University of Pennsylvania
						where I now study business and statistics in the Wharton School.
						However, I found myself missing science, math and technology. I've started to
						take some computer science courses and learn a little web development
						on the side (and built this website) to catch up.
						<br/><br/>
						Nowadays I spend my time rock climbing, drumming, and
						learning all I can while I have the freedom to do so.
						<br/><br/>
						Feel free to check out my apps and resume above if
						you're looking for some of my work. If you have any
						questions, suggestions, or comments don't hesitate 
						to contact me! You can reach me at my info below. 
						<br/><br/>
						Thanks for reading!

					</p>
			</div>
			</div>


		)
	}
}