import React, { PureComponent } from 'react';
import './Projects.css'


import corona from '../Assets/ProjectPics/corona.svg'
import ontra from '../Assets/ProjectPics/ontra.svg'
import pager from '../Assets/ProjectPics/pager.svg'
import foodfinder from '../Assets/ProjectPics/foodfinder.svg'

		  
export default class Projects extends React.Component {
	render() {
        return(
            <div className="projects-container">
                <table>
                    <tr>
                        <th>
                            <a href="https://cshanehsaz.com/coronavirus">
                                <div className="projects-individual-wrapper">
                                    <p className="projects-text"> Coronavirus Visualized </p>
                                    <div className="projects-individual-background0">
                                        <img className="projects-image" src={corona} alt="Coronavirus Visualized"/>
                                    </div>
                                </div>
                            </a>
                        </th>
                        <th>
                            <a href="http://cshanehsaz.com:3838/ontra">
                                <div className="projects-individual-wrapper">
                                    <p className="projects-text"> Ontra Analytics </p>
                                    <div className="projects-individual-background1">
                                        <div>
                                            <img className="projects-image" src={ontra} alt="Coronavirus Visualized"/>
                                        </div>
                                    </div>
                                </div>    
                            </a>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <a href="https://chrome.google.com/webstore/detail/pager-better-bookmarks/algmiligdohjnkhiejpoancmbjgilgnk" target="_blank">
                                <div className="projects-individual-wrapper">
                                    <p className="projects-text"> Pager Bookmarks </p>
                                    <div className="projects-individual-background2">
                                        <div>
                                            <img className="projects-image" src={pager} alt="Coronavirus Visualized"/>
                                        </div>
                                    </div>
                                </div>    
                            </a>
                        </th>
                        <th>
                            <a href="https://cshanehsaz.com/foodfinder">
                                <div className="projects-individual-wrapper">
                                    <p className="projects-text"> Food Finder </p>
                                    <div className="projects-individual-background3">
                                        <div>
                                            <img className="projects-image" src={foodfinder} alt="Coronavirus Visualized"/>
                                        </div>
                                    </div>
                                </div>    
                            </a>
                        </th>
                    </tr>
                </table>
            </div>
        )
	}
}