import React, { Component } from 'react';
import './Displayddd.css'
import {Button} from 'react-bootstrap'
import dddrest from '../Web Scraper/dddrest.json'
import Tableddd from './Tableddd.js'
import _ from '../../../node_modules/lodash'

export default class Displayddd extends Component {
	constructor(props){
		super(props)

		this.state = {
			master: [],
			places: [],
			names: [],
			usstate:'',
			category: 'Enter your state: e.g. "DE"',
			clicked: 0,
		}

		this.handleInput = this.handleInput.bind(this)
		this.showDDD = this.showDDD.bind(this)
		this.buttonClickSet0 = this.buttonClickSet0.bind(this)
		this.buttonClickSet1 = this.buttonClickSet1.bind(this)
	}


    handleInput(event) {
    	this.setState({
      		usstate: event.target.value,
    	});
  	}

  	buttonClickSet0() {
	    this.setState(state => ({clicked: 0}))
  	}

  	buttonClickSet1() {
	    this.setState(state => ({clicked: 1}))
  	}



  	showDDD() {
  		if(this.state.usstate.length != 2) {
  			return ("MUST BE 2 LETTER ABBREVIATION")
  		}

  		const usstate = this.state.usstate
  		const regex = new RegExp(' ' + usstate + ' ')
  		let myplaces = []
  		let indexes = []
  		let mynames = []
  		let index = 0

  		dddrest[1].map(function(address) {
  			const ans = regex.exec(address)
  			if (ans != undefined) {
  				myplaces.push(ans)
  				indexes.push(index)
  			}
  			index++
  		})

  		indexes.map(function(index) {
  			mynames.push(dddrest[0][index])
  		})

  		const superList = _.zip(myplaces, mynames)
  		
  		this.setState(state => ({places: myplaces,
  								 names: mynames,
  								 master: superList,
  								 clicked: 1}))
  		
  		return(superList)
  	}



	render() {
		if (this.state.clicked===0){
			return(	
				<div>
					<input
	              	className="Searchbar"
	              	onInput={this.handleInput}
	              	placeholder={this.state.category}
	            	/>
	            	<br />
		            <Button onClick={this.showDDD}>Find "Diners, Drive-Ins and Dives" near me!</Button>
				</div>
			)
		}


		if (this.state.clicked===1){
			if(this.state.master.length === 0){
						return(
							<div className="Sorry">
								<p> Sorry, it appears there are no triple d restaurants in your state.
								Please try again with a different state!
								</p>
							</div>
							)
			}
			

			return(
				<div>
					<Button onClick={this.buttonClickSet0}>Find more triple D!</Button>
					<br /><br />
					<div className="tripled">

					

						{this.state.master.map(function(place) {
							return(
								<div key={place[1]}>
									<Tableddd name={place[1]} add={place[0].input} />
									<br />
								</div>
								)
							})
						}
					</div>
				</div>
			)
		}
	}



}