import React, { PureComponent, useState } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron'
//import { Document, Page, View, StyleSheet, Text, PDFViewer } from 'react-pdf'
import { Document, Page } from 'react-pdf';
import resumepng from '../Assets/Resume2.png'
import resume from '../Assets/Resume.pdf'
import Image from 'react-bootstrap/Image'
import './Resume.css'

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default class Resume extends PureComponent {	
	render() {
		// const { pageNumber, numPages } = this.state;

		return (
			<div className = "Resume-page">
				{/* <p> Test test </p> */}
				<a href={resume} target="_blank">
					<p className='resume-link'>Open Resume</p>
				</a>
				<p> No viruses - I promise :)</p>
			</div>
		)
	}
}