import React, { PureComponent } from 'react';

import Jumbotron from 'react-bootstrap/Jumbotron'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import profpic from '../Assets/profpic.svg'
import rockclimbing from '../Assets/rockclimbing.svg'

import './Home.css'

export default class Home extends PureComponent {



	render() {
		return(
			<div className="Home">
					<Row className="Row">
						<Col className="Col">
							<Image className="Home_profpic" roundedCircle src={profpic} />
						</Col>

						<Col className="Col">
							<div className="Home_text">
								<h2>Hey, I'm Cyrus Shanehsaz</h2>
								<br />
								<p>
									<br />
									Feel free to take a look around!
								</p>
								<Button href="/about" className="Home_button" variant="outline-secondary">About</Button>
								<Button href="/projects" className="Home_button" variant="outline-secondary">Projects</Button>
								<Button href="/resume" className="Home_button" variant="outline-secondary">Resume</Button>
							</div>
						</Col>
					</Row>


			</div>
			)
	}
}