import React, { PureComponent } from 'react';
import { Card } from 'react-bootstrap'
import './weretheycancelled.css'

export default class WereTheyCancelled extends React.Component {
	constructor(props) {
        super(props)
        this.state = {
            data: [],
            cancelled: false,
            loading: true
        }

        this.updateName = this.updateName.bind(this);
        this.updateCancelled = this.updateCancelled.bind(this);
        this.updateDescription = this.updateDescription.bind(this);
        this.addPerson = this.addPerson.bind(this);
        this.fetchCancelledPeople = this.fetchCancelledPeople.bind(this);
    }
    
    fetchCancelledPeople() {
        fetch('https://cshanehsaz.com/wtcdata/wtc')
            .then(res => res.json())
            .then(res => {
                this.setState({
                    data: res.data,
                    loading: false
                })
            })

    }

    componentWillMount() {
        let fetchCancelledPeople = this.fetchCancelledPeople;
        let interval = setInterval(function() {fetchCancelledPeople()}, 1000);
        this.setState({interval: interval})
    }

    componentWillUnmount() {
        clearInterval(this.state.interval)
    }

    addPerson(e) {
        e.preventDefault();
        let { name, cancelled, description } = this.state;
        fetch('https://cshanehsaz.com/wtcdata/addperson', {
            method: 'post',
            body: JSON.stringify({
                name: name,
                cancelled: cancelled,
                description: description
            }),
            headers: { "Content-Type": "application/json" }
        })

        this.fetchCancelledPeople();

        this.setState({
            name: '',
            cancelled: false,
            description: ''
        })
    }

    updateName(e) {
        this.setState({name: e.target.value})
    }

    updateCancelled(e) {
        let cancelled = true;
        if(e.target.value !== 'true') {
            cancelled = false;
        }
        this.setState({cancelled: cancelled})
    }

    updateDescription(e) {
        this.setState({description: e.target.value})
    }

	render() {
        let { data, name, description, loading } = this.state;

        if(loading) {
            return(
                <div className="loading">
                    <p style={{margin: "auto"}}> Loading ...</p>
                </div>
            )
        }

		return(
            <div className="wereTheyCancelled">
                <div className="titlesContainer">
                    <p className="wtcTitle">Were They Cancelled</p>
                    <p className="wtcSubtitle"> The world's leading database on who has and has not been cancelled</p>
                </div>
                <div className="mainContainer">
                    {data.map(person => 
                        <div className="personContainer">
                            <p className="personName"><b> {person.people} </b></p>
                            <p className="noMargin"> <b>Cancelled:</b> {person.cancelled} </p>
                            <p className="noMargin"> <b>Reasons:</b> {person.description} </p>
                        </div>
                    )}
                </div>
                
                {/* BREAK: Add new form below */}

                <div className="addNewContainer">
                    <h2> Add someone new </h2>
                    <div className='addnew'>

                        <form onSubmit={this.addPerson}>
                            <label className="addNew-name">
                                Name
                                <br/>
                                <input type="text" style={{width: "100%"}} value={name} onChange={this.updateName} />
                            </label>
                            <br/><br/>

                            <label>
                                Were they cancelled?
                                <br/>
                                <label style={{marginRight: "15px"}}>  
                                    <input 
                                        style={{marginRight: "2px"}} type="radio" value={true} 
                                        checked={this.state.cancelled} onChange={this.updateCancelled} 
                                    />
                                    Yes
                                </label>

                                <label>
                                    <input 
                                        style={{marginRight: "2px"}} type="radio" value={false} 
                                        checked={!this.state.cancelled} onChange={this.updateCancelled}
                                    />
                                No
                                </label>
                            </label>
                            <br/><br/>

                            <label className="addNew-description">
                                Why or why not?
                                {/* <input type="text" value={description} onChange={this.updateDescription} /> */}
                                <br/>
                                <textarea className="addNew-description-input" value={description} onChange={this.updateDescription}/>
                            </label>
                            <br/><br/>

                            <input type="submit" value="Submit" />
                        </form>
                    </div>
                </div>
            </div>
        )
	}
}