import React, { PureComponent } from 'react';
import {
  BrowserRouter,
  Route,
  Link,
} from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Image from 'react-bootstrap/Image'
import favicon from '../Assets/favicon.ico'

import Home from './Home.js'
import About from './About.js'
import Resume from './Resume.js'
import App from '.././foodnearme/App.js'
import TestPage from './TransitionTestPage.js'
import About1 from './About1.js'
import Blog from './Blog.js'
import WereTheyCancelled from './weretheycancelled.js'
import Password from './pw.js'
import Projects from './Projects.js'

import './Navbar.css'

export default class MyNavbar extends PureComponent {
	
	render() {
		return(
			<BrowserRouter>
				{/* <Route path='/coronavirus' component={Virusimr} /> */}
			    <Navbar className="Navbar" sticky="top" expand="lg" bg="dark" variant="dark">
			    	<Navbar.Brand href="/">
			    		<Image rounded src={favicon} className="Navbar_brand"/>
			    	</Navbar.Brand>

			    	<Nav className="ml-auto">
			    		<Nav.Link className="Navbar_links" href="/">Home</Nav.Link>
			    		<Nav.Link className="Navbar_links" href="/about">About</Nav.Link>
						<Nav.Link className="Navbar_links" href="/projects">Projects</Nav.Link>
			    		<Nav.Link className="Navbar_links" href="/resume">Resume</Nav.Link>
						{/* <Nav.Link className="Navbar_links" href="/blog">Blog</Nav.Link> */}
			    		<Nav.Link className="Navbar_links" disabled></Nav.Link>

			    	</Nav>
			    </Navbar>
			    <Route exact path='/' component={Home} />
	          	<Route path='/about' component={About1} />
	          	<Route path='/resume' component={Resume} />
	          	<Route path='/foodfinder' component={App} />
				<Route path='/blog' component={Blog} />
				<Route path='/weretheycancelled' component={Password} />
				<Route path='/projects' component={Projects} />

          	</BrowserRouter>
        )
    }
}
