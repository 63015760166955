import React, { PureComponent } from 'react';
		  
export default class Post1 extends PureComponent {

	render() {
		return (
            <div>
                <h1> This is a post </h1>
                <p> Here is the text of the post </p>
                <br/>
                <p> Now I'm going to say my opinion on a subject </p>
            </div>
			)
	}
}

