import React, { PureComponent } from 'react';
import './Contact.css'

import Badge from 'react-bootstrap/Badge'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import linkedin from '../Assets/linkedin.png'
import Image from 'react-bootstrap/Image'
import twitter from '../Assets/twitter.png'
import github from '../Assets/github.png'


		  
export default class Contact extends PureComponent {

	render() {
		return (
			<div className="Contact">
				<h2>
					Contact 
				</h2>

				<a className="Contact_links" href="mailto:me@cshanehsaz.com">   
					cyrus@cshanehsaz.com
				</a>
				<br />
				<br />
				<Container>
					<Row>
						<Col>
							<a className="Contact_links" href="https://www.linkedin.com/in/cyrus-s-446181127/" target="_blank">
								<Image className="Contact_linkedin" src={linkedin} />
								&nbsp; LinkedIn
						
							</a>
						</Col>
					</Row>
					<Row>
						<Col>
							<a className="Contact_links" href="https://twitter.com/CShanehsaz?lang=en" target="_blank">
								<Image className="Contact_twitter" src={twitter} />
								&nbsp; Twitter
							</a>
						</Col>
					</Row>
					<Row>
						<Col>
							<a className="Contact_links" href="https://github.com/cshanehsaz" target="_blank">
								<Image className="Contact_github" src={github} />
								&nbsp; Github
							</a>
						</Col>
					</Row>
					<br />
					<br />
					<Row>
						<Col>
							<p className="Contact_copyright">© 2020 Cyrus Shanehsaz</p>
						</Col>
					</Row>
				</Container>

				{/*<Container>
					<Row>
						<Col></Col>
						<Col md="auto">
							<Badge pill variant="primary">
								Like
							</Badge>
						</Col>
						<Col md="auto">
							<Badge pill variant="primary">
								Tweet
							</Badge>
						</Col>
						<Col></Col>
					</Row>
				</Container>*/}
			</div>

			)

	}
}